import React from "react"
import {
  Section,
  Container,
  Column,
  Columns,
  Button,
  Content,
} from "bloomer"

import Layout from "../components/layout"
import SEO from "../components/seo"
import data from "../data/oraculo/jogo-de-tarot/jogo-de-tarot.json"

export default class JogoDeTarot extends React.Component {
  state = {
    selected: [],
    random:[],
    inverted:[],
  }

  
  handleClick = event => {
    let random = data;
    for (let i = random.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [random[i], random[j]] = [random[j], random[i]];
    }
  
    //let random = data.sort(() => .5 - Math.random())
    this.setState({random:random})
    this.setState({selected:[]})
    this.setState({inverted:[]})
  }

  render() {
    var parent = this
    const {selected, random, inverted} = this.state;

    let items =""

    if (selected.length === 3){
         items = selected.map(function(item, i){
            if(item['id']){
              let inv = (inverted[i])? "inv":"";
              const image = require("../data/oraculo/jogo-de-tarot/images/"+item["id"]+inv+"gde.gif")
              let interpretation = (inverted[i])? item["inverted_interpretation"] : item["interpretation"];
              
              return (
                <Content key={i} className="media">
                  <div className="media-left">
                    <img src={image} alt={item['title']} />
                  </div>
                  <div className="media-content">
                    <h3>Nome: {item["title"]}</h3>
                    <p>Descrição: {item["description"]}</p>
                    <p>Interpretação: {interpretation}</p>
                  </div>
                </Content>
              )
            }else{
              return ''
            }
            
        });
        items.push(<Button className="is-primary" onClick={() => { parent.handleClick()}}>Jogar</Button>)

    }
    if(random.length && selected.length<3){
      items = random.map(function(item, i){
        if(item['id']){
          const active = selected.includes(item)
          let inv = (inverted[i])? "inv":"";
          const image = require("../data/oraculo/jogo-de-tarot/images/"+item["id"]+inv+"gde.gif")
          return (
            <Button 
              key={i}
              onClick={() => {
                if(!active){
                  const selectedtmp = selected;   
                  selectedtmp.push(item);   
                  parent.setState({selected:selectedtmp})

                  const invertedtmp = inverted;   
                  invertedtmp.push(Math.random() < 0.5);   
                  parent.setState({inverted:invertedtmp})
                }                            
              }}
              className={active?'is-active column ':'column'}
            >
              <img src={image} alt={item['name']}/>
            </Button>
          )
        }else{
            return '';
        }
        
    });
    items = <Columns className="deck" isMobile isMultiline>{items}</Columns>
    }
    if(selected.length<3 && !random.length){
      items = <Button className="is-primary" onClick={() => { parent.handleClick()}}>Jogar</Button>
    }
    
 
    return (
      <Layout>
        <SEO title="Jogo de Tarot grátis no Estrela Guia. O que as cartas dizem? - Estrelaguia Guia" />
          <Content>
            <h1>Jogo de Tarot</h1>
            <p>Siga os passos abaixo para jogar</p>
            <p> Mentalize sua pergunta</p>
            <p>Clique sobre uma carta abaixo</p>
            <p>Aguarde que ela apareça completamente</p>
            <p>Repita os passos 2 e 3 para escolher mais 2 cartas</p>
          </Content>
          <Content>
            {items}
          </Content>
      </Layout>
    )
  }
}


